import React, { useEffect} from 'react'
import { Box, Flex, Text } from "@chakra-ui/react"
import { BiMessageDetail } from 'react-icons/bi'
import { useScrollToBottom } from 'react-scroll-to-bottom';
import { Tooltip } from "@chakra-ui/react"
// import { UsersContext } from 'Context/usersContext'
import { bg_color } from 'utils/configs'
import { GoReply } from "react-icons/go";


const ContentMessage = (props) => {
    const {notifications} = props
    const setBgcolor = (value) => {
        let color = "#F4F6F8"
        return color
    }
    return (
        <>
            {notifications.length > 0 ?
                notifications.map((noti, i) =>
                (<Box key={i+'_noti'} className={`notification`} mb="12px">
                    <div className={'noti_content'}>
                        <Flex fontSize='11px' opacity='.7' ml='5px' textAlign={'left'}>
                            <div className='user max_string max_string_user'>
                                <Tooltip label={noti.user} aria-label='A tooltip' >
                                    {noti.user}
                                </Tooltip>
                            </div>
                            <div className="mx-3">-</div>
                            <div className="datetime">{noti?.time}</div>
                            
                        </Flex>
                        
                        <Text fontSize='14px' className='msg white-space' bg={setBgcolor()} p="6px" borderRadius='8px' color="white">
                            <span>{noti.content}</span>
                        </Text>

                    </div>
                </Box>)
                )
                :
                <Flex alignItems='center' justifyContent='center' mt='.5rem' w='100%'>
                    <Box mr='2'>-----</Box>
                    <BiMessageDetail fontSize='1rem' />
                    <Text ml='1' fontWeight='400'>Chưa có thông báo</Text>
                    <Box ml='2'>-----</Box>
                </Flex>
            }
        </>
    )
}

export default ContentMessage
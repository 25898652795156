import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { MainContext } from '../../Context/mainContext'
import { SocketContext } from '../../Context/socketContext'
import { Flex, Heading, IconButton, Input, Spinner } from "@chakra-ui/react"
import { RiArrowRightLine } from "react-icons/ri"
import { useToast } from "@chakra-ui/react"
import { UsersContext } from '../../Context/usersContext'

const Login = () => {
    const socket = useContext(SocketContext)
    const { name, setName, room, setRoom } = useContext(MainContext)
    const [isAdmin, setAdmin] = useState(0)
    const [color, setColor] = useState(0)
    const [tokenMd5, setToken] = useState('')
    const history = useHistory()
    const toast = useToast()
    const { setUsers } = useContext(UsersContext)

    //Checks to see if there's a user already present

    useEffect(() => {
        const query = new URLSearchParams(window.location.search)
        let name = query.get('name')
        let room = query.get('room')
        let token = query.get('token')
        let is_admin = query.get('is_admin')
        let color = query.get('color')
        if(!name) {
            name = window.localStorage.getItem('name')
            if(!name) return
        }
        window.localStorage.setItem('name', name)
        if(!room) {
            room = window.localStorage.getItem('room')
            if(!room) return
        }
        window.localStorage.setItem('room', room)
        if(!is_admin) {
            is_admin = window.localStorage.getItem('is_admin')
            if(!is_admin) is_admin = 0
        }
        window.localStorage.setItem('is_admin', is_admin)

        if(!color) {
            color = window.localStorage.getItem('color')
            if(!color) color = 0
        }
        window.localStorage.setItem('color', color)

        setToken(token)
        setAdmin(is_admin)
        setName(name);
        setRoom(room);
        setColor(color);

        socket.on("users", users => {
            setUsers(users)
        })

    }, [])
    useEffect(() => {
        if(!name || !room) return
        handleClick();
    }, [name, room])
    //Emits the login event and if successful redirects to chat and saves user data
    const handleClick = () => {
        socket.emit('login', { name, room, check_sum: tokenMd5, is_admin: isAdmin, color }, error => {
            if (error) {
                console.log(error)
                return toast({
                    position: "top",
                    title: "Error",
                    description: error,
                    status: "error",
                    duration: 5000,
                    isClosable: true,
                })
            }
            history.push('/chat')
        })
    }

    return (
        <Flex className='login' flexDirection='column' mb='8'>
            <Heading as="h1" size="4xl" textAlign='center' mb='8' fontFamily='DM Sans' fontWeight='600' letterSpacing='-2px'>
                {/* Chat khảo thí */}
                <Spinner
                    thickness='4px'
                    speed='0.65s'
                    emptyColor='gray.200'
                    color='blue.500'
                    size='xl'
                />
            </Heading>
            <Flex className="form" gap='1rem' flexDirection={{ base: "column", md: "row" }}>
                {/* <Input variant='filled' mr={{ base: "0", md: "4" }} mb={{ base: "4", md: "0" }} type="text" placeholder='User Name' value={name} onChange={e => setName(e.target.value)} />
                <Input variant='filled' mr={{ base: "0", md: "4" }} mb={{ base: "4", md: "0" }} type="text" placeholder='Room Name' value={room} onChange={e => setRoom(e.target.value)} />
                <IconButton colorScheme='blue' isRound='true' icon={<RiArrowRightLine />} onClick={handleClick}></IconButton> */}
            </Flex>
        </Flex>
    )
}

export default Login

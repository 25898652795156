import React from 'react'
import io from 'socket.io-client'
import {domains} from 'utils/configs'
const SocketContext = React.createContext()

const SocketProvider = ({ children }) => {
    const ENDPOINT = domains.HOST_DOMAIN;
    const socket = io(ENDPOINT, { transports: ['websocket', 'polling'] })
    return (
        <SocketContext.Provider value={socket}>
            {children}
        </SocketContext.Provider>
    )
}

export { SocketContext, SocketProvider }
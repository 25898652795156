import React, { useEffect} from 'react'
import { Box, Flex, Text } from "@chakra-ui/react"
import { BiMessageDetail } from 'react-icons/bi'
import { useScrollToBottom } from 'react-scroll-to-bottom';
import { Tooltip } from "@chakra-ui/react"
// import { UsersContext } from 'Context/usersContext'
import { bg_color } from 'utils/configs'
import { GoReply } from "react-icons/go";


const ContentMessage = ({messages, addReply, name, setInputFocus, goToBottom, setGoToBottom}) => {
    const scrollToBottom = useScrollToBottom();
    const scrollToDiv = (id)=> {
        let target = document.getElementById(id);
        target.scrollIntoView({ behavior: 'smooth', block: "center", inline: "nearest" });
    }
    
    const setBgcolor = (value) => {
        let color = "#F4F6F8"
        switch (Number(value)) {
            case 1:
                color = bg_color.color1
                break;
            case 2:
                color = bg_color.color2
                break;
            default:
                
                break;
        }
        return color
    }

    useEffect(()=> {
        if(goToBottom) {
            scrollToBottom();
            setGoToBottom(false);
        };
    },[goToBottom])
    return (
        <>
            {messages.length > 0 ?
                messages.map((msg, i) =>
                (<Box key={i} className={`message ${msg.user === name ? "my-message" : ""}`} mb="12px">
                    <div  id={msg.uuid}></div>
                    
                    <div className={'message_content'}>
                        <Flex fontSize='11px' opacity='.7' ml='5px' textAlign={msg.user == name ? "right" : 'left'}>
                            <div className='user max_string max_string_user'>
                                <Tooltip label={msg.user} aria-label='A tooltip' >
                                    { msg.user == name ? "Tôi" : msg.user }
                                </Tooltip>
                            </div>
                            <div className="mx-3">-</div>
                            <div className="datetime">{msg?.time}</div>
                            
                        </Flex>
                        {(msg?.repMessage) && (
                            <Text onClick={()=> {
                                scrollToDiv(`scroll_to_${msg?.repMessage.uuid}`)
                            }} className='msg_reply max_string' m="0 0.5rem" p="6px" pb="4px"  bg='#919eab61' borderTopRadius='8px' color='black'>{msg?.repMessage.content}</Text>
                        )}
                        
                        <Text className='msg white-space' p="6px" bg={setBgcolor(msg.color)} borderRadius='8px' color="white">
                            <span>{msg.content}</span>
                            {msg.user != name && (
                                <span className="reply_icon">
                                    <GoReply onClick={ () => {
                                        addReply(msg);
                                        setInputFocus();
                                    }}/>
                                </span>
                            )}
                            
                        </Text>

                    </div>
                    <div id={`scroll_to_${msg.uuid}`} ></div>
                </Box>)
                )
                :
                <Flex alignItems='center' justifyContent='center' mt='.5rem' w='100%'>
                    <Box mr='2'>-----</Box>
                    <BiMessageDetail fontSize='1rem' />
                    <Text ml='1' fontWeight='400'>Chưa có tin nhắn</Text>
                    <Box ml='2'>-----</Box>
                </Flex>
            }
        </>
    )
}

export default ContentMessage
import React, { useContext, useEffect, useState, useRef } from 'react'
import { MainContext } from 'Context/mainContext'
import { SocketContext } from 'Context/socketContext'
import { Flex, Spinner, IconButton } from "@chakra-ui/react"
import { IoMdClose } from "react-icons/io";
import ScrollToBottom from 'react-scroll-to-bottom';
import { AiOutlineNotification } from "react-icons/ai";
import { HiOutlinePlus } from "react-icons/hi2";
// import { UsersContext } from 'Context/usersContext'
import { VietIME } from 'text_vni/vime'
import ContentNotify from '../Content/ContentNotify'
import ModalNoti from '../Modal/sendNoti'
const useFocus = () => {
    const htmlElRef = useRef(null)
    const setFocus = () => {htmlElRef.current &&  htmlElRef.current.focus()}

    return [ htmlElRef, setFocus ] 
}
const TabMessage = (props) => {
    const { name } = useContext(MainContext)
    const g_vietTyper = new VietIME;
    const socket = useContext(SocketContext)
    const { notifications, isConnected, is_admin } = props
    const [isOpenModal, setOpenModal] = useState(false);

    const [msgRep, setMsgRep] = useState(null)

    const openModalAddNoti = () => {
        setOpenModal(true);
    }
    const closeModal = () => {
        setOpenModal(false);
    }
    return (
        <Flex className='room' flexDirection='column' width={{ base: "100%" }} height={{ base: "100%" }}>
             {
                Number(is_admin) === 1 && (
                    <div className="show_modal_add_noti">
                        <IconButton colorScheme='blue' minW='40px' h='40px' fontSize="2rem" isRound='true' icon={<HiOutlinePlus />} onClick={openModalAddNoti}></IconButton>
                    </div>
                )
            }
            <ScrollToBottom className={`notifications ContainerElementID ${msgRep ? 'rep_active': ''}`} followButtonClassName="button_go_bottom" debug={false} mode="bottom">
                <ContentNotify notifications={notifications}/>
            </ScrollToBottom>
            <ModalNoti isOpenModal={isOpenModal} closeModal={closeModal} />
        </Flex>
    )
}
export default TabMessage;
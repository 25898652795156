import React, {useEffect, useContext, useState} from 'react'
import {
    Text,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalFooter,
    ModalBody,
    ModalCloseButton,
    useDisclosure,
    FormControl,
    FormErrorMessage,
    Textarea,
    Button
} from '@chakra-ui/react'
import { MainContext } from 'Context/mainContext'
import { SocketContext } from 'Context/socketContext'
import { VietIME } from 'text_vni/vime'
import { useToast } from "@chakra-ui/react"


const SendNoti = ({isOpenModal, closeModal}) => {
    const { name, room, setName, setRoom } = useContext(MainContext)
    const { isOpen, onOpen, onClose } = useDisclosure()
    const socket = useContext(SocketContext);
    const [notify, setNotify] = useState('');
    const initialRef = React.useRef()
    const g_vietTyper = new VietIME;
    const toast = useToast();
    const [isError, setIsError] = useState(false);
    useEffect(() => {
        if(!isOpenModal) return
        onOpen()
    }, [isOpenModal])

    const _handleKeyPress = (e, id) => {
      let $this = document.getElementById(id);
      g_vietTyper.targetOnKeyPress( e, $this );
      if(notify == '') return
      setNotify(e.target.value)
    }

    const handleSendNotify = () => {
        let mes = notify.trim()
        if(mes == '') return setIsError(true)
        setIsError(false)
        socket.emit('sendNoti', {user: name, content: mes, rep: null}, error => {
          if (error) {
              console.log(error)
              return toast({
                  position: "top",
                  title: "Error",
                  description: error,
                  status: "error",
                  duration: 2000,
                  isClosable: true,
              })
          }
      
        })
        setNotify('');
        closeModal()
        setTimeout(()=>{
          onClose()
        }, 500)
        
    }

    return (
      <>
        <Modal
          initialFocusRef={initialRef}
          isOpen={isOpen}
          size="sm"
          onClose={()=>{
            closeModal()
            onClose()
          }}
          isCentered
        >
          <ModalOverlay />
          <ModalContent overflow={'auto'}>
            <ModalHeader fontSize={'15px'} fontWeight={'bold'} color="white" bg="#0043A5">Thêm thông báo</ModalHeader>
            <ModalCloseButton color="white" _focus={{ boxShadow: 'none' }}/>
            <ModalBody pb={2}>
              <FormControl isInvalid={isError}>
                <Textarea
                  id="inboxNoti"
                  placeholder='Nhập nội dung tại đây...'
                  size='sm'
                  rows="10"
                  resize={'none'}
                  autoComplete="off"
                  onChange={e => {
                    setNotify(e.target.value)
                  }}
                  onKeyPress={(e)=> {
                      _handleKeyPress(e, 'inboxNoti')
                  }}
                  
                  value={notify}
                  isRequired
                />
    
                {isError && (
                  <FormErrorMessage>Nội dung thông báo không được để trống!</FormErrorMessage>
                )}
              </FormControl>
              
            </ModalBody>
  
            <ModalFooter pt={1}>
              <Button fontSize={'lg'} h={"2rem"} onClick={()=>{
                handleSendNotify()
              }}>
                <Text className="text-input" fontSize="13px" mr="2">Gửi</Text> <img src="/primary-shape.svg"/>
              </Button>
              {/* <Button 
              fontSize={'xs'}
              h={"2rem"}
              onClick={()=>{
                closeModal()
                onClose()
              }}>Hủy</Button> */}
            </ModalFooter>
          </ModalContent>
        </Modal>
      </>
    )
  }
  export default SendNoti